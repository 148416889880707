@import "@nbp/dnafe-material-ui/dist/styles/variables";

.DefaultLayout {
  .MuiButtonBase-root.MuiButton-root.MuiButton-contained.round-button {
    border-radius: 50%;
    width: $spacing-xl;
    height: $spacing-xl;
    padding: 0;
    min-width: 0;
    font-size: 1.25rem;
    font-weight: 700;
  }

  .HeaderMenu .MuiBreadcrumbs-ol .MuiBreadcrumbs-li {
    overflow: hidden;
  }

  .blue-line {
    background-color: $color-primary;
    color: $color-neutral-90;
    padding: $spacing-xxs 0;
    margin-bottom: $spacing-s;

    .blue-line-inner {
      width: $content-width;
      margin: 0 auto 0 auto;
      justify-content: flex-end;
      gap: 0 $spacing-l;

      @media (max-width: $breakpoint-tablet) {
        width: auto;
        margin: 0 $spacing-xl 0 $spacing-xl;
      }

      @media (max-width: $breakpoint-mobile) {
        width: auto;
        margin: 0 $spacing-s 0 $spacing-s;
      }
    }
  }
}
