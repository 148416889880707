@import "@nbp/dnafe-material-ui/dist/styles/variables";

.RegistrationAuthorityAnalysisPage {
  .number-column-header {
    width: 1%;
    white-space: nowrap;
  }

  .PageHeader .inner {
    flex-wrap: wrap;
  }

  .filter-row {

    .MuiFormHelperText-root {
      display: none;
    }

    .SearchField {
      min-width: 100%;

      .MuiTextField-root {
        min-width: 280px;
      }
    }

    @media (max-width: $breakpoint-tablet) {
      flex-direction: column;

      .SearchField .MuiTextField-root {
        min-width: 100%;
      }

      .autocomplete-column {
        flex: 2;
      }
    }

    @media (max-width: $breakpoint-mobile) {
      .date-range-column {
        flex-direction: column;
      }
    }
  }
}
