@import "@nbp/dnafe-material-ui/dist/styles/variables";

.column-created-at {
  width: 1%;
  white-space: nowrap;
}

.MuiFormControl-root .MuiInputBase-root.MuiOutlinedInput-root .MuiSelect-select.MuiOutlinedInput-input {
  padding-right: 32px;
}

.LoadingOverlay {
  margin-top: -$spacing-xxs;

  > * {
    margin-top: $spacing-xxs;
  }
}

.MuiDialog-container {
  .LoadingOverlay {
    margin-top: 0;

    > * {
      margin-top: 0;
    }
  }
}

.MuiFormControl-root .MuiInputBase-root.MuiOutlinedInput-root textarea.MuiOutlinedInput-input {
  padding: $spacing-xs 0 $spacing-xs 0;
}
