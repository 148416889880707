@import "@nbp/dnafe-material-ui/dist/styles/variables";

.PageHeader {
  margin-bottom: $spacing-xxl;

  .inner {
    display: flex;
    gap: $spacing-s;
  }

  h1, h2, h3 {
    margin: 0;
    word-break: break-word;
  }

  p {
    margin-bottom: 0;
  }

  @media (max-width: $breakpoint-tablet) {
    margin-bottom: $spacing-xl;
  }

  @media (max-width: $breakpoint-mobile) {
    margin-bottom: $spacing-l;
  }

  &.subHeader {
    margin-bottom: $spacing-s;

    @media (max-width: $breakpoint-tablet) {
      margin-bottom: $spacing-xs;
    }
  }

  .page-header-title {
    flex: auto;
  }

  .page-header-right {
    display: flex;
    flex-wrap: wrap;
    gap: $spacing-xs;
  }

  .page-header-children {
    text-align: right;

    button {
      white-space: nowrap;
    }
  }
}
