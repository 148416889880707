@import "@nbp/dnafe-material-ui/dist/styles/variables";

.FieldWithCopy {

  .MuiInputBase-root.MuiOutlinedInput-root {
    padding-right: 0;
  }

  .MuiInputBase-multiline {
    padding-top: 0;
    padding-bottom: 0;
    min-height: $spacing-xxl;
  }

  .end-adornment {
    height: 100%;

    button {
      margin-top: 3px;
    }
  }

}
