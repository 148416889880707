@import "@nbp/dnafe-material-ui/dist/styles/variables";

.LandingPage {
  .MuiCard-root .MuiCardContent-root {
    text-align: left;
    display: flex;
    flex-direction: column;

    h3 {
      margin-bottom: $spacing-m;
    }

    svg {
      margin-bottom: $spacing-s;
    }

    ul {
      padding: 0;
      list-style-type: none;
      flex: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin-bottom: 0;
    }

    > a {
      margin-top: $spacing-m;
      display: inline-block;

      .MuiButton-root {
        width: auto;
        padding: $spacing-xxxs $spacing-xl;
      }
    }

  }

}
