@import "@nbp/dnafe-material-ui/dist/styles/variables";

.InstitutionMergeDialog {
  .SearchField {
    text-align: right;
  }

  .DataTable {
    overflow-y: scroll;
    max-height: calc(100vh - 350px);

    .selected, .selected.MuiTableRow-root:nth-child(even), {
      background-color: $color-primary-95;
    }
    .disabled, .disabled.MuiTableRow-root:nth-child(even), {
      background-color: $color-neutral-95;

      &.clickable {
        cursor: default;
      }
    }
  }
}

.MuiPopover-root .InstitutionMergeDialogPopover {
  max-width: 500px;
}
