.KeycloakLoading, .KeycloakLoading.LoadingOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .PageContent {
    margin-top: 20%;

    p {
      text-align: center;
    }
  }
}
